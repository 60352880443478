import { SparePartCategory } from "./System";
export const BwtsSystemResult =
    {
        capacity: "FIT 1X3000",
        troManufacturer: "Filtrex",
        isEx: "Non Ex",
        filterManufacturer: "Filter Manufacturer example",
        image: "",
        spareParts: [
            {
                id: 1,
                category: SparePartCategory.Recommended.toString(),
                itemNumber: "101-1002-049",
                productName: "DIFFERENTIAL PRESSURE INSTRUMENT ACB-906_9200",
                recomendedQuantity: 1,
                description: "DIFFERENTIAL PRESSURE INSTRUMENT ACB-906_9200"
            },
            {
                id: 2,
                category: SparePartCategory.Recommended.toString(),
                itemNumber: "101-1002-072",
                productName: "THRUST BEARING  FOR FILTER ACB 9200",
                recomendedQuantity: 1,
                description: "THRUST BEARING  FOR FILTER ACB 9200"
            },
            {
                id: 3,
                category: SparePartCategory.Recommended.toString(),
                itemNumber: "101-1002-083",
                productName: "BACKWASH SHAFT UPPER BUSHING  FOR FILTER ACB 9200",
                recomendedQuantity: 1,
                description: "BACKWASH SHAFT UPPER BEARING  FOR FILTER ACB 9200"
            },
            {
                id: 4,
                category: SparePartCategory.Recommended.toString(),
                itemNumber: "101-1002-094",
                productName: "BACKWASH SHAFT BOTTOM BUSHING  FOR FILTER ACB 9200",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 5,
                category: SparePartCategory.Recommended.toString(),
                itemNumber: "101-1002-103",
                productName: "SELF CLEANING FILTERING ELEMENT FOR ACB9200",
                recomendedQuantity: 1,
                description: "SELF CLEANING FILTERING ELEMENT FOR ACB9200"
            },
            {
                id: 6,
                category: SparePartCategory.Recommended.toString(),
                itemNumber: "101-1002-139",
                productName: "ELECTRIC MOTOR FOR FILTER ACB-985/ 999/ 9100/ 9120/ 9200",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 7,
                category: SparePartCategory.Recommended.toString(),
                itemNumber: "101-1002-144",
                productName: "101-1002-144	GEAR REDUCER FOR FILTER ACB-985/999/9100 EX",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 8,
                category: SparePartCategory.Critical.toString(),
                itemNumber: "101-1002-167",
                productName: "FILTER ACB-9200 SET OF GASKETS",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 9,
                category: SparePartCategory.Recommended.toString(),
                itemNumber: "101-2002-005",
                productName: "MIXING DEVICE SHAFT 1100mm",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 10,
                category: SparePartCategory.Recommended.toString(),
                itemNumber: "101-2002-014",
                productName: "SEALING KIT (ORING+FLANGE+INOX BEARING)",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 11,
                category: SparePartCategory.Recommended.toString(),
                itemNumber: "101-2002-028",
                productName: "PROPELLER FOR NAT PVC",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 12,
                category: SparePartCategory.Recommended.toString(),
                itemNumber: "101-2002-029",
                productName: "PVC FLANGE FOR MIXING MOTOR",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 13,
                category: SparePartCategory.Recommended.toString(),
                itemNumber: "101-4001-018",
                productName: "POWER MODULE K700903 FKM-USCG MODULE 30-50V/2X125-100A",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 14,
                category: SparePartCategory.Critical.toString(),
                itemNumber: "101-4001-021",
                productName: "FUSE 20A 500V 10.3x38.1mm l1=120kA",
                recomendedQuantity: 10,
                description: ""
            },
            {
                id: 15,
                category: SparePartCategory.Critical.toString(),
                itemNumber: "101-4001-030",
                productName: "CONtroL MODULE COMPONENT: FAN CONtroL MODULE",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 16,
                category: SparePartCategory.Critical.toString(),
                itemNumber: "101-4001-046",
                productName: "WATER COOLING PART I-CONNECTION",
                recomendedQuantity: 18,
                description: ""
            },
            {
                id: 17,
                category: SparePartCategory.Critical.toString(),
                itemNumber: "101-4001-047",
                productName: "WATER COOLING PART L-CONNECTION",
                recomendedQuantity: 11,
                description: ""
            },
            {
                id: 18,
                category: SparePartCategory.Recommended.toString(),
                itemNumber: "101-4001-082",
                productName: "PEX TUBE",
                recomendedQuantity: 2,
                description: ""
            },
            {
                id: 19,
                category: SparePartCategory.Critical.toString(),
                itemNumber: "101-4001-092",
                productName: "U-TUBE, WATER",
                recomendedQuantity: 4,
                description: ""
            },
            {
                id: 20,
                category: SparePartCategory.Critical.toString(),
                itemNumber: "101-4001-141",
                productName: "WATER CONNECTOR TOOL",
                recomendedQuantity: 8,
                description: ""
            },
            {
                id: 21,
                category: SparePartCategory.Critical.toString(),
                itemNumber: "101-4001-144",
                productName: "RIBBON CABLE CONtroL BOARD DISPLAY BOARD",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 22,
                category: SparePartCategory.Consumables.toString(),
                itemNumber: "101-5001-003",
                productName: "REAGENT KIT - TOTAL CHLORINE 12 MONTH SUPPLY",
                recomendedQuantity: 2,
                description: ""
            },
            {
                id: 23,
                category: SparePartCategory.Critical.toString(),
                itemNumber: "101-5001-005",
                productName: "REPLACEMENT TUBING/ CUVETTE  KIT NON-EX",
                recomendedQuantity: 2,
                description: ""
            },
            {
                id: 24,
                category: SparePartCategory.Critical.toString(),
                itemNumber: "101-5001-011",
                productName: "TUBE & CHECK VALVE FLUSH KIT",
                recomendedQuantity: 2,
                description: ""
            },
            {
                id: 25,
                category: SparePartCategory.Critical.toString(),
                itemNumber: "101-5001-012",
                productName: "FLOW THROUGH CUVETTE",
                recomendedQuantity: 2,
                description: ""
            },
            {
                id: 26,
                category: SparePartCategory.Critical.toString(),
                itemNumber: "101-5001-013",
                productName: "CHECK VALVE REPLACEMENT KIT",
                recomendedQuantity: 2,
                description: ""
            },
            {
                id: 27,
                category: SparePartCategory.Critical.toString(),
                itemNumber: "101-5001-014",
                productName: "T STRAINER SCREEN",
                recomendedQuantity: 2,
                description: ""
            },
            {
                id: 28,
                category: SparePartCategory.Critical.toString(),
                itemNumber: "101-5001-015",
                productName: "INTAKE ASSY WATER, CLX -XT/EX",
                recomendedQuantity: 2,
                description: ""
            },
            {
                id: 29,
                category: SparePartCategory.Critical.toString(),
                itemNumber: "101-5001-024",
                productName: "OPTICS ASSEMBLY XT",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 30,
                category: SparePartCategory.Maintenance.toString(),
                itemNumber: "101-5001-040",
                productName: "INLET CHECK VALVE",
                recomendedQuantity: 2,
                description: ""
            },
            {
                id: 31,
                category: SparePartCategory.Maintenance.toString(),
                itemNumber: "101-5001-044",
                productName: "PRESSURE REGULATOR",
                recomendedQuantity: 2,
                description: ""
            },
            {
                id: 32,
                category: SparePartCategory.Maintenance.toString(),
                itemNumber: "101-5001-062",
                productName: "REPLACEMENT PURGE SOLENOID (BLACK COIL WIRE)",
                recomendedQuantity: 2,
                description: ""
            },
            {
                id: 33,
                category: SparePartCategory.Critical.toString(),
                itemNumber: "101-5001-077",
                productName: "REPLACEMENT PCB & CHASSIS KIT - FIRMWARE 1.5.20",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 34,
                category: SparePartCategory.Recommended.toString(),
                itemNumber: "101-5002-034",
                productName: "CARTRIDGE U-HIGH / STD. FET 300 24V",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 35,
                category: SparePartCategory.Recommended.toString(),
                itemNumber: "101-5002-041",
                productName: "BACKPLANE TYPE4 FET/FEP, FOR FEP 311, NoN EX",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 36,
                category: SparePartCategory.Critical.toString(),
                itemNumber: "101-5003-016",
                productName: "FLOW SWITCH DIGITAL 24V CERAMIC",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 37,
                category: SparePartCategory.Critical.toString(),
                itemNumber: "101-5004-005",
                productName: "PRESSURE SWITCH 1-10BAR",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 38,
                category: SparePartCategory.Critical.toString(),
                itemNumber: "101-5004-020",
                productName: "FEMALE CONNECTOR M12",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 39,
                category: SparePartCategory.Critical.toString(),
                itemNumber: "101-5004-074",
                productName: "PRESSURE TRANSMITTER 0-10bar",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 40,
                category: SparePartCategory.Critical.toString(),
                itemNumber: "101-5007-006",
                productName: "FIXED HYDROGEN SENSOR 0-100% LEL",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 41,
                category: SparePartCategory.Critical.toString(),
                itemNumber: "101-5007-015",
                productName: "HYDROGEN SENSOR CALIBRATION GAS CASE",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 42,
                category: SparePartCategory.Consumables.toString(),
                itemNumber: "101-5007-017",
                productName: "HYDROGEN SENSOR GAS CARTRIDGE CH4 34% LEL TEST CYLINDER",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 43,
                category: SparePartCategory.Calibration.toString(),
                itemNumber: "101-5007-025",
                productName: "CALIBRATION KEYPAD HANDHELD",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 44,
                category: SparePartCategory.Recommended.toString(),
                itemNumber: "101-6003-1128",
                productName: "DOUBLE ACTING PNEUMATIC ACTUATOR AT701U DA",
                recomendedQuantity: 2,
                description: ""
            },
            {
                id: 45,
                category: SparePartCategory.Recommended.toString(),
                itemNumber: "101-6003-146",
                productName: "VALVE ELECtro-PNEUMATIC POSITIONER MAR",
                recomendedQuantity: 2,
                description: ""
            },
            {
                id: 46,
                category: SparePartCategory.Recommended.toString(),
                itemNumber: "101-6003-209",
                productName: "LIMIT SWITCH BOX 2XSPDT 210N NON EX",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 47,
                category: SparePartCategory.Recommended.toString(),
                itemNumber: "101-6003-215",
                productName: "SOLENOID 5/2 1/4'' NAMUR ALUM 24 VDC EMBEDED COIL/CONNECTOR",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 48,
                category: SparePartCategory.Critical.toString(),
                itemNumber: "101-6003-403",
                productName: "SOLENOID 5/2 1/4'' NAMUR ALUM 24VDC EMBEDED COIL/CONNECTOR",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 49,
                category: SparePartCategory.Recommended.toString(),
                itemNumber: "101-6003-404",
                productName: "",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 50,
                category: SparePartCategory.Recommended.toString(),
                itemNumber: "101-6003-406",
                productName: "DOUBLE ACTING PNEUMATIC ACTUATOR AT201U DA",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 51,
                category: SparePartCategory.Critical.toString(),
                itemNumber: "101-6003-453",
                productName: "",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 52,
                category: SparePartCategory.Critical.toString(),
                itemNumber: "101-6004-001",
                productName: "486586 LUCIFER LUG FOR STANDARD VERSION 32mm COILS",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 53,
                category: SparePartCategory.Critical.toString(),
                itemNumber: "101-6004-002",
                productName: "SOLENOID VALVE CONNECTOR WITH LED 24V DC",
                recomendedQuantity: 2,
                description: ""
            },
            {
                id: 54,
                category: SparePartCategory.Recommended.toString(),
                itemNumber: "101-7001-082",
                productName: "MECHANICAL SEAL FOR CENTRIFUGAL PUMP MN32-125 TO MN80-160",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 55,
                category: SparePartCategory.Recommended.toString(),
                itemNumber: "101-7002-001",
                productName: "PNEUMATIC DIAPHRAGM PUMP 8LT/MIN 3,5BAR",
                recomendedQuantity: 2,
                description: ""
            },
            {
                id: 56,
                category: SparePartCategory.Recommended.toString(),
                itemNumber: "101-7002-033",
                productName: "PNEUMATIC DIAPHRAGM PUMP 17LT/MIN 2BAR",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 57,
                category: SparePartCategory.Recommended.toString(),
                itemNumber: "101-7003-018",
                productName: "FOOT VALVE FOR DOSING PUMP UP TO 460LT/H DVDF, FKM/EPDM, CER",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 58,
                category: SparePartCategory.Recommended.toString(),
                itemNumber: "101-7003-019",
                productName: "INJECTION UNIT FOR DOSING PUMP UP TO 460LT/H PVDF,EPDM,CERAM",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 59,
                category: SparePartCategory.Recommended.toString(),
                itemNumber: "101-7003-024",
                productName: "COMPLETE DOSING HEAD SERVICE KIT FOR DOSING PUMP 120LT/H",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 60,
                category: SparePartCategory.Critical.toString(),
                itemNumber: "101-7003-037",
                productName: "SERVICE KIT FOR DOSING PUMP DDA 120-7 [99151316]",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 61,
                category: SparePartCategory.Consumables.toString(),
                itemNumber: "101-8000-036",
                productName: "ELECtroMECHANICAL RELAY 24V",
                recomendedQuantity: 3,
                description: ""
            },
            {
                id: 62,
                category: SparePartCategory.Consumables.toString(),
                itemNumber: "101-9004-001",
                productName: "-",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 63,
                category: SparePartCategory.Consumables.toString(),
                itemNumber: "101-9004-008",
                productName: "SODIUM BISULFITE SOLUTION (25LT CANISTER)",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 64,
                category: SparePartCategory.Consumables.toString(),
                itemNumber: "101-9004-011",
                productName: "CITRIC ACID POWDER (25KG BAG)",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 65,
                category: SparePartCategory.Critical.toString(),
                itemNumber: "101-9005-218",
                productName: "PVC REDUCER BSP 1/2'' MALE THREAD TO BSP 3/8'' FEMALE THREAD",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 66,
                category: SparePartCategory.Critical.toString(),
                itemNumber: "102-5005-004",
                productName: "TEMPERATURE SENSOR MODULE 0-100C",
                recomendedQuantity: 1,
                description: ""
            },
            {
                id: 67,
                category: SparePartCategory.Recommended.toString(),
                itemNumber: "102-7002-012",
                productName: "SERVICE KIT FOR PNEUMATIC DIAPHRAGM PUMP PD01P",
                recomendedQuantity: 2,
                description: ""
            },
            {
                id: 68,
                category: SparePartCategory.Recommended.toString(),
                itemNumber: "101-5005-004",
                productName: "SERVICE KIT FOR PNEUMATIC DIAPHRAGM PUMP PD01P",
                recomendedQuantity: 1,
                description: ""
            }

        ],
        components: [
            {
                id: 1,
                name: "Filter",
                sparePart: [
                    {
                        id: 1,
                        installationId:1,
                        category: SparePartCategory.Recommended.toString(),
                        itemNumber: "101-1002-049",
                        productName: "DIFFERENTIAL PRESSURE INSTRUMENT ACB-906_9200",
                        recomendedQuantity: 1,
                        description: "DIFFERENTIAL PRESSURE INSTRUMENT ACB-906_9200",
                        positions:[{
                            lat: 398.5,
                            lon: 26,
                        }]
                    },
                    {
                        id: 2,
                        installationId:2,
                        category: SparePartCategory.Recommended.toString(),
                        itemNumber: "101-1002-072",
                        productName: "THRUST BEARING  FOR FILTER ACB 9200",
                        recomendedQuantity: 1,
                        description: "THRUST BEARING  FOR FILTER ACB 9200",
                        positions: [{
                            lat: 558,
                            lon: 516.5,
                        }],
                    },
                    {
                        id: 3,
                        installationId:3,
                        category: SparePartCategory.Recommended.toString(),
                        itemNumber: "101-1002-083",
                        productName: "BACKWASH SHAFT UPPER BUSHING  FOR FILTER ACB 9200",
                        recomendedQuantity: 1,
                        description: "BACKWASH SHAFT UPPER BEARING  FOR FILTER ACB 9200",
                        positions: [{
                            lat: 383.5,
                            lon: 543,
                        }],
                    },
                    {
                        id: 4,
                        installationId:4,
                        category: SparePartCategory.Recommended.toString(),
                        itemNumber: "101-1002-094",
                        productName: "BACKWASH SHAFT BOTTOM BUSHING  FOR FILTER ACB 9200",
                        recomendedQuantity: 1,
                        description: "",
                        positions: [{
                            lat: 125.5,
                            lon: 549.5,
                        }],
                    },
                    {
                        id: 5,
                        installationId:5,
                        category: SparePartCategory.Recommended.toString(),
                        itemNumber: "101-1002-103",
                        productName: "SELF CLEANING FILTERING ELEMENT FOR ACB9200",
                        recomendedQuantity: 1,
                        description: "SELF CLEANING FILTERING ELEMENT FOR ACB9200",
                        positions: [{
                            lat: 273,
                            lon: 552,
                        }],
                    },
                    {
                        id: 6,
                        installationId:6,
                        category: SparePartCategory.Recommended.toString(),
                        itemNumber: "101-1002-139",
                        productName: "ELECTRIC MOTOR FOR FILTER ACB-985/ 999/ 9100/ 9120/ 9200",
                        recomendedQuantity: 1,
                        description: "",
                        positions: [{
                            lat: 497.5,
                            lon: 65,
                        }],
                    },
                    {
                        id: 7,
                        installationId:7,
                        category: SparePartCategory.Recommended.toString(),
                        itemNumber: "101-1002-144",
                        productName: "101-1002-144	GEAR REDUCER FOR FILTER ACB-985/999/9100 EX",
                        recomendedQuantity: 1,
                        description: "",
                        positions: [{
                            lat: 578.5,
                            lon: 259.5,
                        }],
                    },
                    {
                        id: 8,
                        installationId:8,
                        category: SparePartCategory.Critical.toString(),
                        itemNumber: "101-1002-167",
                        productName: "FILTER ACB-9200 SET OF GASKETS",
                        recomendedQuantity: 1,
                        description: "",
                        positions: [{
                            lat: 438.5,
                            lon: 323,
                        }],
                }],
                componentDrawing: {
                    id: 1,
                    imageUrl: "/drawings/filter.png",
                    imageUrl_t: "/drawings/filter_t.png",
                    startingTopCornerLat:116,
                    startingTopCornerLng:0,
                    startZoom:-5
                },
            },
            {
                id: 2,
                name: "TRO",
                sparePart: [
                    {
                        id: 26,
                        installationId:1,
                        category: SparePartCategory.Critical.toString(),
                        itemNumber: "101-5001-013",
                        productName: "CHECK VALVE REPLACEMENT KIT",
                        recomendedQuantity: 2,
                        description: "CHECK VALVE REPLACEMENT KIT",
                        positions: [{
                            lat: 882,
                            lon: 482,
                        }],
                    },
                    {
                        id: 33,
                        installationId:2,
                        category: SparePartCategory.Critical.toString(),
                        itemNumber: "101-5001-077",
                        productName: "REPLACEMENT PCB & CHASSIS KIT - FIRMWARE 1.5.20",
                        recomendedQuantity: 1,
                        description: "REPLACEMENT PCB & CHASSIS KIT",
                        positions: [{
                            lat: 788,
                            lon: 302,
                        }],
                    },
                    {
                        id: 23,
                        installationId:3,
                        category: SparePartCategory.Critical.toString(),
                        itemNumber: "101-5001-005",
                        productName: "REPLACEMENT TUBING/ CUVETTE  KIT NON-EX",
                        recomendedQuantity: 2,
                        description: "REPLACEMENT TUBING/ CUVETTE  KIT NON-EX",
                        positions: [{
                            lat: 792,
                            lon: 1064,
                        }],
                    },
                    {
                        id: 29,
                        installationId:4,
                        category: SparePartCategory.Critical.toString(),
                        itemNumber: "101-5001-024",
                        productName: "OPTICS ASSEMBLY XT",
                        recomendedQuantity: 1,
                        description: "OPTICS ASSEMBLY XT",
                        positions: [{
                            lat: 582,
                            lon: 250,
                        }],
                    },
                    {
                        id: 25,
                        installationId:5,
                        category: SparePartCategory.Critical.toString(),
                        itemNumber: "101-5001-012",
                        productName: "FLOW THROUGH CUVETTE",
                        recomendedQuantity: 2,
                        description: "FLOW THROUGH CUVETTE",
                        positions: [{
                            lat: 462,
                            lon: 206,
                        }],
                    },
                    {
                        id: 32,
                        installationId:6,
                        category: SparePartCategory.Maintenance.toString(),
                        itemNumber: "101-5001-062",
                        productName: "REPLACEMENT PURGE SOLENOID (BLACK COIL WIRE)",
                        recomendedQuantity: 2,
                        description: "REPLACEMENT PURGE SOLENOID (BLACK COIL WIRE)",
                        positions: [{
                            lat: 384,
                            lon: 482,
                        }],
                    },
                    {
                        id: 72,
                        installationId:8,
                        category: SparePartCategory.Critical.toString(),
                        itemNumber: "101-5002-015",
                        productName: "T STRAINER SCREEN",
                        recomendedQuantity: 1,
                        description: "T STRAINER SCREEN",
                        positions: [{
                            lat: 357,
                            lon: 82,
                        }],
                    },
                    {
                        id: 31,
                        installationId:9,
                        category: SparePartCategory.Maintenance.toString(),
                        itemNumber: "101-5001-044",
                        productName: "PRESSURE REGULATOR",
                        recomendedQuantity: 2,
                        description: "PRESSURE REGULATOR",
                        positions: [{
                            lat: 246,
                            lon: 58,
                        }],
                    },
                    {
                        id: 30,
                        installationId:10,
                        category: SparePartCategory.Maintenance.toString(),
                        itemNumber: "101-5001-040",
                        productName: "INLET CHECK VALVE",
                        recomendedQuantity: 2,
                        description: "INLET CHECK VALVE",
                        positions: [{
                            lat: 98,
                            lon: 336,
                        }],
                    },
                ],
                componentDrawing: {
                    id: 1,
                    imageUrl: "/drawings/tro.png",
                    imageUrl_t: "/drawings/tro_t.png",
                    startingTopCornerLat:470,
                    startingTopCornerLng:680,
                    startZoom:-1,
                    minZoom:-1
                },
            },
            {
                id: 3,
                name: "TR",
                sparePart: [
                    {
                        id: 13,
                        installationId:1,
                        category: SparePartCategory.Recommended.toString(),
                        itemNumber: "101-4001-018",
                        productName: "POWER MODULE K700903 FKM-USCG MODULE 30-50V/2X125-100A",
                        recomendedQuantity: 1,
                        description: "POWER MODULE K700903 FKM-USCG MODULE 30-50V/2X125-100A",
                        positions: [{
                            lat: 409,
                            lon: 20,
                        }],
                    },
                    {
                        id: 16,
                        installationId:2,
                        category: SparePartCategory.Critical.toString(),
                        itemNumber: "101-4001-046",
                        productName: "WATER COOLING PART I-CONNECTION",
                        recomendedQuantity: 18,
                        description: "WATER COOLING PART I-CONNECTION",
                        positions: [{
                            lat: 305,
                            lon: 567,
                        }],
                    },
                    {
                        id: 17,
                        installationId:3,
                        category: SparePartCategory.Critical.toString(),
                        itemNumber: "101-4001-047",
                        productName: "WATER COOLING PART L-CONNECTION",
                        recomendedQuantity: 11,
                        description: "WATER COOLING PART L-CONNECTION",
                        positions: [{
                            lat: 403,
                            lon: 569,
                        }],
                    },
                    {
                        id: 19,
                        installationId:4,
                        category: SparePartCategory.Critical.toString(),
                        itemNumber: "101-4001-092",
                        productName: "U-TUBE, WATER",
                        recomendedQuantity: 4,
                        description: "U-TUBE, WATER",
                        positions: [{
                            lat: 180,
                            lon: 567,
                        }],
                    },
                ],
                componentDrawing: {
                    id: 1,
                    imageUrl: "/drawings/tr.png",
                    imageUrl_t: "/drawings/tr_t.png",
                    startingTopCornerLat:116,
                    startingTopCornerLng:0,
                    startZoom:-5,
                    minZoom:-1
                },
            },
            {
                id: 4,
                name: "DP",
                sparePart: [
                    {
                        id: 59,
                        installationId:1,
                        category: SparePartCategory.Recommended.toString(),
                        itemNumber: "101-7003-024",
                        productName: "COMPLETE DOSING HEAD SERVICE KIT FOR DOSING PUMP 120LT/H",
                        recomendedQuantity: 1,
                        description: "COMPLETE DOSING HEAD SERVICE KIT FOR DOSING PUMP DDA 120LT/H",
                        positions: [{
                            lat: 535,
                            lon: 41,
                        },
                        {
                            lat: 717,
                            lon: 278,
                        },
                        {
                            lat: 782,
                            lon: 490,
                        },
                        {
                            lat: 787,
                            lon: 540,
                        },
                        {
                            lat: 816,
                            lon: 605,
                        },
                        {
                            lat: 930,
                            lon: 423,
                        }],
                    },
                    {
                        id: 60,
                        installationId:2,
                        category: SparePartCategory.Critical.toString(),
                        itemNumber: "101-7003-037",
                        productName: "SERVICE KIT FOR DOSING PUMP DDA 120-7 [99151316]",
                        recomendedQuantity: 1,
                        description: "SERVICE KIT FOR DOSING PUMP DDA 120-7",
                        positions: [{
                            lat: 1000,
                            lon: 418,
                        },
                        {
                            lat: 852,
                            lon: 491,
                        },
                        {
                            lat: 884,
                            lon: 605,
                        },
                        {
                            lat: 950,
                            lon: 738,
                        }]
                    },
                ],
                componentDrawing: {
                    id: 1,
                    imageUrl: "/drawings/dp.png",
                    imageUrl_t: "/drawings/dp_t.png",
                    startingTopCornerLat:480,
                    startingTopCornerLng:650,
                    startZoom:-5,
                    minZoom:-1.3
                },
            },
            {
                id: 5,
                name: "DAP",
                sparePart: [
                    {
                        id: 67,
                        installationId:1,
                        category: SparePartCategory.Recommended.toString(),
                        itemNumber: "102-7002-012",
                        productName: "SERVICE KIT FOR PNEUMATIC DIAPHRAGM PUMP PD01P",
                        recomendedQuantity: 2,
                        description: "SERVICE KIT FOR PNEUMATIC DIAPHRAGM PUMP PD01P",
                        positions: [{
                            lat: 919.5,
                            lon: 65,
                        },
                        {
                            lat: 692,
                            lon: 299,
                        },
                        {
                            lat: 766,
                            lon: 652,
                        },
                        {
                            lat: 547,
                            lon: 760,
                        },
                        {
                            lat: 259,
                            lon: 695,
                        },
                        {
                            lat: 181,
                            lon: 908,
                        }],
                    },
                ],
                componentDrawing: {
                    id: 1,
                    imageUrl: "/drawings/dap.png",
                    imageUrl_t: "/drawings/dap_t.png",
                    startingTopCornerLat:480,
                    startingTopCornerLng:650,
                    startZoom:-5,
                    minZoom:-1.3
                },
            },
            {
                id: 6,
                name: "NAT",
                sparePart: [
                    {
                        id: 9,
                        installationId:1,
                        category: SparePartCategory.Recommended.toString(),
                        itemNumber: "101-2002-005",
                        productName: "MIXING DEVICE SHAFT 1100mm",
                        recomendedQuantity: 1,
                        description: "MIXING DEVICE SHAFT 1100mm",
                        positions: [
                            {
                                lat: 576,
                                lon: 126.25,
                            }
                        ]
                    },
                    {
                        id: 10,
                        installationId:2,
                        category: SparePartCategory.Recommended.toString(),
                        itemNumber: "101-2002-014",
                        productName: "SEALING KIT (ORING+FLANGE+INOX BEARING)",
                        recomendedQuantity: 1,
                        description: "SEALING KIT (ORING+FLANGE+INOX BEARING)",
                        positions: [{
                            lat: 541,
                            lon: 552,
                        }],
                    },
                    {
                        id: 11,
                        installationId:3,
                        category: SparePartCategory.Recommended.toString(),
                        itemNumber: "101-2002-028",
                        productName: "PROPELLER FOR NAT PVC",
                        recomendedQuantity: 1,
                        description: "PROPELLER FOR NAT PVC",
                        positions: [{
                            lat: 115,
                            lon: 549,
                        }],
                    },
                    {
                        id: 12,
                        installationId:4,
                        category: SparePartCategory.Recommended.toString(),
                        itemNumber: "101-2002-029",
                        productName: "PVC FLANGE FOR MIXING MOTOR",
                        recomendedQuantity: 1,
                        description: "PVC FLANGE FOR MIXING MOTOR",
                        positions: [{
                            lat: 485,
                            lon: 549,
                        }],
                    },
                ],
                componentDrawing: {
                    id: 1,
                    imageUrl: "/drawings/nat.png",
                    imageUrl_t: "/drawings/nat_t.png",
                    startingTopCornerLat:116,
                    startingTopCornerLng:0,
                    startZoom:-5,
                    minZoom:-1
                },
            },
            {
                id: 7,
                name: "ON OFF VALVE",
                sparePart: [
                    {
                        id: 47,
                        installationId:1,
                        category: SparePartCategory.Recommended.toString(),
                        itemNumber: "101-6003-215",
                        productName: "SOLENOID 5/2 1/4'' NAMUR ALUM 24 VDC EMBEDED COIL/CONNECTOR",
                        recomendedQuantity: 1,
                        description: "SOLENOID 5/2 1/4\" NAMUR ALUM 24 VDC EMBEDED COIL/CONNECTOR",
                        positions: [
                            {
                                lat: 245,
                                lon: 556,
                            }
                        ]
                    },
                    {
                        id: 46,
                        installationId:2,
                        category: SparePartCategory.Recommended.toString(),
                        itemNumber: "101-6003-209",
                        productName: "LIMIT SWITCH BOX 2XSPDT 210N NON EX",
                        recomendedQuantity: 1,
                        description: "LIMIT SWITCH BOX 2XSPDT 210N NON EX",
                        positions: [
                            {
                                lat: 563,
                                lon: 524.5,
                            }
                        ]
                    },
                    {
                        id: 44,
                        installationId:3,
                        category: SparePartCategory.Recommended.toString(),
                        itemNumber: "101-6003-1128",
                        productName: "DOUBLE ACTING PNEUMATIC ACTUATOR AT701U DA",
                        recomendedQuantity: 2,
                        description: "DOUBLE ACTING PNEUMATIC ACTUATOR AT701U DA",
                        positions: [
                            {
                                lat: 471,
                                lon: 44,
                            }
                        ]
                    },
                ],
                componentDrawing: {
                    id: 1,
                    imageUrl: "/drawings/onOffValve.png",
                    imageUrl_t: "/drawings/onOffValve_t.png",
                    startingTopCornerLat:116,
                    startingTopCornerLng:0,
                    startZoom:-5,
                    minZoom:-1
                },
            },
            {
                id: 9,
                name: "MANUAL VALVE",
                sparePart: [
                    {
                        id: 46,
                        installationId:1,
                        category: SparePartCategory.Recommended.toString(),
                        itemNumber: "101-6003-209",
                        productName: "LIMIT SWITCH BOX 2XSPDT 210N NON EX",
                        recomendedQuantity: 1,
                        description: "LIMIT SWITCH BOX 2XSPDT 210N NON EX",
                        positions: [
                            {
                                lat: 559,
                                lon: 489.5,
                            }
                        ]
                    },
                ],
                componentDrawing: {
                    id: 1,
                    imageUrl: "/drawings/manualValve.png",
                    imageUrl_t: "/drawings/manualValve_t.png",
                    startingTopCornerLat:116,
                    startingTopCornerLng:0,
                    startZoom:-5,
                    minZoom:-1
                },
            }
        ]
    }
