import React, { Suspense, lazy } from "react";
import { useRoutes, useLocation, Navigate } from "react-router-dom";
import LoadingScreen from "../components/LoadingScreen";
import { Box } from "@mui/material";

// eslint-disable-next-line react/display-name
const Loadable = (Component: React.ElementType) => (props: any) => {
  const { pathname } = useLocation();
  const isDashboard = pathname.includes("/dashboard");

  return (
    <Suspense fallback={null}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <ApplicationLayout />,
      children: [
        {
          path: "",
          element: <Navigate to="/bwts" replace />,
        },
        {
          path: "bwts",
          element: (
            <Box
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BwtsSystemFinderPage />
            </Box>
          ),
        },
        {
          path: "BwtsSystemViewPage",
          element: <BwtsSystemViewPage />,
        },
        {
          path: "BwtsExplodedDiagramViewPage",
          element: <BwtsExplodedDiagramViewPage />,
        },
        {
          path: "CheckoutOfRequestForQuotation",
          element: <CheckoutOfRequestForQuotation />,
        },
      ],
    },
  ]);
}

const ApplicationLayout = Loadable(lazy(() => import("../layouts")));
const BwtsSystemFinderPage = Loadable(
  lazy(() => import("../pages/BwtsSystemFinderPage"))
);
const BwtsSystemViewPage = Loadable(
  lazy(() => import("../pages/BwtsSystemViewPage"))
);
const BwtsExplodedDiagramViewPage = Loadable(
  lazy(() => import("../pages/BwtsExplodedDiagramViewPage"))
);
const CheckoutOfRequestForQuotation = Loadable(
  lazy(() => import("../pages/CheckoutOfRequestForQuotation"))
);
