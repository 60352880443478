import { gql } from "@apollo/client";

//--------------------------------------- SHIPOWNERS ------------------------------------------------
export const GET_BWTS_SYSTEM_ID_BY_SERIAL_NUMBER = gql`
  query getSystemIdBySerialNumber($serialNumber: String!){
    getSystemIdBySerialNumber(serialNumber: $serialNumber){
      spSystemId
      filterManufacturer
      troManufacturer
      isEx
    }
  }
`;

export const GET_BWTS_SYSTEM_BY_SERIAL_NUMBER = gql`
  query getBwtsSystem($serialNumber: String!) {
    getBwtsSystem(serialNumber: $serialNumber) {

        capacity
        isEx
        filterManufacturer
        troManufacturer
        image
        spareParts {
          id
            category
            itemNumber
            productName
            recomendedQuantity
        }
        components {
          id
          name
          componentDrawing {
            id
            imageUrl
          }
          spareParts {
            id
            category
            itemNumber
            productName
            recomendedQuantity
            position {
              lat
              lon
            }
          }
        }
    }
  }
`;

export const GET_BWTS_SYSTEM_BY_SELECTED_FIELDS = gql`
  query getSystembyConfiguration(
    $filterManufacturer: String!
    $troManufacturer: String!
    $isEx: String!
    $capacity: String!
  ){
    getSystembyConfiguration(
      filterManufacturer: $filterManufacturer
      troManufacturer: $troManufacturer
      isEx: $isEx
      capacity: $capacity
    ){
      capacity
      isEx
      filterManufacturer
      troManufacturer
      image
      spareParts {
        id
          category
          itemNumber
          productName
          recomendedQuantity
          description
      }
      components {
        id
        name
        componentDrawing {
          id
          imageUrl
          imageUrl_t
          startZoom
          startingTopCornerLng
          startingTopCornerLat

        }
        spareParts {
          id
          installationId
          category
          itemNumber
          productName
          recomendedQuantity
          description
          positions {
            lat
            lon
          }
        }
      }
    }
  }
`;

export const GET_BWTS_SYSTEM_ID_BY_SELECTED_FIELDS = gql`
  query findSystemIdAccordingToCritiria(
    $filterManufacturer: String!
    $troManufacturer: String!
    $isEx: String!
    $capacity: String!
  ){
    findSystemIdAccordingToCritiria(
      filterManufacturer: $filterManufacturer
      troManufacturer: $troManufacturer
      isEx: $isEx
      capacity: $capacity
    )
  }
`;

export const GET_BWTS_SYSTEM_BY_SYSTEM_ID = gql`
  query getCompleteSystemBySystemId($systemId: Int!, $filterManufacturer: String!, $troManufacturer: String!){
    getCompleteSystemBySystemId(systemId: $systemId, filterManufacturer: $filterManufacturer, troManufacturer:  $troManufacturer){
      id
      capacity
      isEx
      filterManufacturer
      troManufacturer
      image
      spareParts {
        id
          category
          itemNumber
          productName
          recomendedQuantity
          description
      }
      components {
        id
        name
        hasMap
        componentDrawing {
          id
          imageUrl
          imageUrl_t


        }
        spareParts {
          id
          installationId
          category
          itemNumber
          productName
          recomendedQuantity
          description
          positions {
            lat
            lon
          }
        }
      }
    }
  }
`;
