import { HashRouter } from "react-router-dom";
import NotistackProvider from "./components/NotistackProvider";
import RtlLayout from "./components/RtlLayout";
import ThemeLocalization from "./components/ThemeLocalization";
import ThemePrimaryColor from "./components/ThemePrimaryColor";
import Routes from "./routes";
// Apollo setup
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
} from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import ThemeConfig from "./theme";
import { createMockClient } from 'mock-apollo-client';
import { GET_BWTS_SYSTEM_BY_SERIAL_NUMBER } from "./graphQL/Queries";
import { BwtsSystemResult } from "./graphQL/MockData";

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_SERVER_URI + "/graphql",
});

export default function App() {
  const links = [httpLink];
  const link = ApolloLink.from(links);
  const mockClient = createMockClient();

  mockClient.setRequestHandler(
    GET_BWTS_SYSTEM_BY_SERIAL_NUMBER,
    () => Promise.resolve({ data : BwtsSystemResult }));

  // create Client
  const client = new ApolloClient({
    cache: new InMemoryCache({
      addTypename: false,
    }),
    link: link,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "network-only",
      },
    },
  });

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <ApolloProvider client={client}>
                <HashRouter>
                  <Routes />
                </HashRouter>
              </ApolloProvider>
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
