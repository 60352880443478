import { createRoot } from "react-dom/client";
import "./index.module.scss";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import "simplebar/src/simplebar.css";
import "leaflet/dist/leaflet.css";

if (process.env.REACT_APP_MOCK_DATA === "TRUE") {
  require("./utils/mock/api");
}

const eventLogger = (event: any, error: any) => {
  console.log("onKeycloakEvent", event, error);
};

const tokenLogger = (tokens: any) => {
  if (tokens != undefined && tokens !== null) {
    if (tokens.token != undefined && tokens.token != null) {
      window.sessionStorage.setItem("jwt", tokens.token);
    }
  }
};

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
