import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

interface GradientsPaletteOptions {
  primary: string;
  info: string;
  success: string;
  warning: string;
  error: string;
}

interface ChartPaletteOptions {
  violet: string[];
  blue: string[];
  green: string[];
  yellow: string[];
  red: string[];
}

declare module "@mui/material/styles/createPalette" {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
  interface Palette {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
  }
  interface PaletteOptions {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
  }
}

declare module "@mui/material" {
  interface Color {
    0: string;
    500_8: string;
    500_12: string;
    500_16: string;
    500_24: string;
    500_32: string;
    500_48: string;
    500_56: string;
    500_80: string;
  }
}

// SETUP COLORS
const PRIMARY = {
  lighter: "#6280BC",
  light: "#375081",
  main: "#2B3E64",
  dark: "#253556",
  darker: "#182339",
};
const SECONDARY = {
  lighter: "#47DAFF",
  light: "#1FD2FF",
  main: "#00A5CF",
  dark: "#0093B8",
  darker: "#005266",
};
const INFO = {
  lighter: "#F3F3F3",
  light: "#F3F3F3",
  main: "#F3F3F3",
  dark: "#E0E0E0",
  darker: "#ADADAD",
};
const SUCCESS = {
  lighter: "#7FCD7A",
  light: "#55BC4E",
  main: "#43A43D",
  dark: "#378532",
  darker: "#2A6827",
};
const WARNING = {
  lighter: "#FFF7CD",
  light: "#FFE16A",
  main: "#FFC107",
  dark: "#B78103",
  darker: "#7A4F01",
};
const ERROR = {
  lighter: "#FFE7D9",
  light: "#FFA48D",
  main: "#FF312E",
  dark: "#B72136",
  darker: "#7A0C2E",
  epered: "#EF3E42",
};

const APP_TEXT = {
  0: "#000000",
};

const APP_PRIMARY = {
  0: "#2B3E64",
  100: "#2B3E64",
  200: "#2B3E64",
  300: "#2B3E64",
  400: "#2B3E64",
  500: "#2B3E64",
  600: "#2B3E64",
  700: "#2B3E64",
  800: "#2B3E64",
  900: "#2B3E64",
  500_8: alpha("#2B3E64", 0.08),
  500_12: alpha("#2B3E64", 0.12),
  500_16: alpha("#2B3E64", 0.16),
  500_24: alpha("#2B3E64", 0.24),
  500_32: alpha("#2B3E64", 0.32),
  500_48: alpha("#2B3E64", 0.48),
  500_56: alpha("#2B3E64", 0.56),
  500_80: alpha("#2B3E64", 0.8),
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ["#826AF9", "#9E86FF", "#D0AEFF", "#F7D2FF"],
  blue: ["#2D99FF", "#83CFFF", "#A5F3FF", "#CCFAFF"],
  green: ["#2CD9C5", "#60F1C8", "#A4F7CC", "#C0F2DC"],
  yellow: ["#FFE700", "#FFEF5A", "#FFF7AE", "#FFF3D6"],
  red: ["#FF6C40", "#FF8F6D", "#FFBD98", "#FFF2D4"],
};

const COMMON = {
  common: { black: "#000", white: "#fff" },
  primary: { ...PRIMARY, contrastText: "#fff" },
  secondary: { ...SECONDARY, contrastText: "#fff" },
  info: { ...INFO, contrastText: "#fff" },
  success: { ...SUCCESS, contrastText: APP_PRIMARY[800] },
  warning: { ...WARNING, contrastText: APP_PRIMARY[800] },
  error: { ...ERROR, contrastText: "#fff" },
  grey: APP_PRIMARY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: APP_PRIMARY[500_24],
  action: {
    hover: APP_PRIMARY[500_8],
    selected: APP_PRIMARY[500_16],
    disabled: APP_PRIMARY[500_80],
    disabledBackground: APP_PRIMARY[500_24],
    focus: APP_PRIMARY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  light: {
    ...COMMON,
    text: { primary: APP_TEXT[0], secondary: APP_TEXT[0], disabled: APP_TEXT[0] },
    background: { paper: "#fff", default: "#fff", neutral: APP_PRIMARY[200] },
    action: { active: APP_PRIMARY[600], ...COMMON.action },
  },
  dark: {
    ...COMMON,
    text: { primary: "#fff", secondary: APP_TEXT[0], disabled: APP_TEXT[0] },
    background: { paper: APP_PRIMARY[800], default: APP_PRIMARY[900], neutral: APP_PRIMARY[500_16] },
    action: { active: APP_PRIMARY[500], ...COMMON.action },
  },
};

export default palette;
